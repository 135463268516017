import { useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import React, { useCallback, FC } from 'react';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { RecordType, StarState, transferNameOwnership } from 'starmap-api';
import { isSelfOwned } from '../../utils/Util';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useSnackbar } from 'notistack';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useSendTransaction } from '../../utils/SendTransaction';

function maybePubKey(str: string) {
  try {
    return new PublicKey(str);
  } catch {
    return null;
  }
}

async function sendTransfer(
  connection: Connection,
  wallet: WalletContextState,
  name: string,
  type: RecordType,
  destination: PublicKey
) {
  if (!wallet.publicKey) {
    throw new WalletNotConnectedError();
  }
  console.log('Send blockchain transfer request');
  const instr = await transferNameOwnership(name, type, wallet.publicKey, destination);
  const trans = new Transaction();
  trans.add(instr);
  return wallet.sendTransaction(trans, connection);
}

function startIcon(busy: boolean, record: StarState) {
  if (busy) return <CircularProgress color="inherit" size={19} />;
  return record.isAssignedAndValid ? <CheckBoxOutlineBlankIcon /> : <IndeterminateCheckBoxIcon />;
}

type Props = {
  name: string;
  recordType: RecordType;
  record: StarState;
  onTransfer: (success: boolean) => void;
};

export const TransferButton: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { sendTransaction } = useSendTransaction();
  const { enqueueSnackbar } = useSnackbar();
  const [destination, setDestination] = React.useState('');
  const [help, setHelp] = React.useState('');
  const [busy, setBusy] = React.useState(false);
  const buttonDisabled = React.useMemo(
    () => maybePubKey(destination) == null || !isSelfOwned(props.record, wallet.publicKey),
    [destination, props.record, wallet.publicKey]
  );

  const handleDestinationChange = useCallback(async (event) => {
    const newDestination = (event.target.value as string).replace(' ', '');
    setDestination(newDestination);
    if (newDestination.length == 0) {
      setHelp('');
      return;
    }
    const newPubKey = maybePubKey(newDestination);
    if (newPubKey != null) {
      setHelp(`Valid destination: ${newPubKey.toBase58()}`);
    } else {
      setHelp('Invalid base58 public key');
    }
  }, []);

  const handleTransfer = useCallback(async () => {
    setBusy(true);
    let message = '';
    let result = false;
    try {
      const promise = sendTransfer(connection, wallet, props.name, props.recordType, new PublicKey(destination));
      result = await sendTransaction(promise);
      message = result ? 'Transfer success' : 'Transfer failure';
    } catch (e: any) {
      message = `Transfer failed: ${e}.`;
    }
    setBusy(false);
    console.log(message);
    enqueueSnackbar(message, { variant: result ? 'success' : 'error' });
    props.onTransfer(result);
  }, [connection, destination, enqueueSnackbar, props, sendTransaction, wallet]);

  return (
    <React.Fragment>
      <TextField
        id="code"
        label="Base58 Destination Address"
        variant="outlined"
        value={destination}
        onChange={handleDestinationChange}
      />

      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {help}
      </Typography>

      <Button
        disabled={buttonDisabled}
        onClick={handleTransfer}
        variant="contained"
        startIcon={startIcon(busy, props.record)}
      >
        Transfer Ownership
      </Button>
    </React.Fragment>
  );
};
