import { useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Transaction, Connection } from '@solana/web3.js';
import React, { useCallback, FC, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { RecordType, deleteNameRegistry, StarState } from 'starmap-api';
import { isSelfOwned } from '../../utils/Util';
import { useSnackbar } from 'notistack';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useSendTransaction } from '../../utils/SendTransaction';

async function sendDelete(connection: Connection, wallet: WalletContextState, name: string, type: RecordType) {
  if (!wallet.publicKey) {
    throw new WalletNotConnectedError();
  }
  console.log('Delete');
  const instr = await deleteNameRegistry(name, type, wallet.publicKey);
  const trans = new Transaction();
  trans.add(instr);
  return wallet.sendTransaction(trans, connection);
}

type Props = {
  name: string;
  recordType: RecordType;
  record: StarState;
  onDelete: (success: boolean) => void;
};

function startIcon(busy: boolean, record: StarState) {
  if (busy) return <CircularProgress color="inherit" size={19} />;
  return record.isAssignedAndValid ? <CheckBoxOutlineBlankIcon /> : <IndeterminateCheckBoxIcon />;
}

export const DeleteButton: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const selfOwned = isSelfOwned(props.record, wallet.publicKey);
  const [busy, setBusy] = useState(false);
  const { sendTransaction } = useSendTransaction();
  const buttonDisabled = useMemo(
    () => props.recordType === RecordType.Invalid || !selfOwned,
    [props.recordType, selfOwned]
  );

  const handleDelete = useCallback(
    async (_) => {
      if (props.recordType === RecordType.Invalid) {
        return;
      }
      let result = false;
      let message = '';
      setBusy(true);
      try {
        const promise = sendDelete(connection, wallet, props.name, props.recordType);
        result = await sendTransaction(promise);
        message = result ? 'Delete success' : 'Delete failed';
      } catch (e: any) {
        message = `Delete failed: ${e}.`;
      }
      setBusy(false);
      console.log(message);
      enqueueSnackbar(message, { variant: result ? 'success' : 'error' });
      props.onDelete(result);
    },
    [connection, enqueueSnackbar, props, sendTransaction, wallet]
  );

  return (
    <React.Fragment>
      <Button
        disabled={buttonDisabled}
        onClick={handleDelete}
        variant="contained"
        startIcon={startIcon(busy, props.record)}
      >
        Delete
      </Button>
    </React.Fragment>
  );
};
