import React, { useCallback, FC } from 'react';
import { CircularProgress, Fade, Tooltip, IconButton, Stack, TextField, Typography } from '@mui/material';
import { parseName } from 'starmap-api';
import { useNameRecord } from '../../context/NamesContext';
import { HelpOutline } from '@mui/icons-material';

const defaultHelpMessage = 'Enter an email address, phone number (+1 ..), or .sol Bonfida domain';

function niceError(e: string) {
  console.log(e);
  if (e === 'INVALID_COUNTRY') return 'Invalid country code';
  return defaultHelpMessage;
}

export const SearchBar: FC = () => {
  const nameInfo = useNameRecord();
  const handleNameChange = useCallback(
    async (event) => {
      const newName = event.target.value;
      const { recordType, normalizedName, parseError } = parseName(newName);
      if (parseError) nameInfo.setLookupMessage(niceError(parseError.message));
      await nameInfo.updateName(newName, normalizedName, recordType);
    },
    [nameInfo]
  );

  return (
    <React.Fragment>
      <Stack>
        <Typography variant="body1" component="div" sx={{ pb: 2 }}>
          Find someone on Solana
        </Typography>
        <Stack direction="row" alignItems="center">
          <TextField
            id="name"
            label="Search"
            variant="outlined"
            value={nameInfo.name}
            onChange={handleNameChange}
            sx={{ width: '95%' }}
          />
          {HelpTooltip()}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {nameInfo.isLoading && <CircularProgress color="secondary" size={15} sx={{ mt: 1 }} />}
          <Typography variant="body2" sx={{ pt: 1 }}>
            {nameInfo.lookupMessage}
          </Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

function HelpTooltip() {
  return (
    <Tooltip
      TransitionComponent={Fade}
      title={
        <React.Fragment>
          <Typography variant="caption" display="block">
            Look up a wallet public key via:
          </Typography>
          <Typography variant="caption" display="block">
            {' '}
            • Phone number (eg. +1 234 567 8910)
          </Typography>
          <Typography variant="caption" display="block">
            {' '}
            • Email address
          </Typography>
          <Typography variant="caption" display="block">
            {' '}
            • Bonfida URL (eg. starmap.sol)
          </Typography>
          <Typography variant="caption" display="block">
            For more info, see the FAQ below ⬇
          </Typography>
        </React.Fragment>
      }
    >
      <IconButton>
        <HelpOutline />
      </IconButton>
    </Tooltip>
  );
}
