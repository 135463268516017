import React, { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { SearchBar } from './SearchBar';
import { TabPanel } from './TabPanel';
import HelpAccordion from './Help';
import { useNameRecord } from '../../context/NamesContext';

export const Page: FC = () => {
  const { programValid } = useNameRecord();
  return (
    <Grid container justifyContent="center">
      <Box sx={{ m: 0, pt: 5, px: 5, height: 1, maxWidth: 720 }}>
        <Stack spacing={2}>
          <Box sx={{ p: 4, border: 1, borderRadius: 5, borderColor: 'divider' }}>
            {programValid && (
              <Stack spacing={4}>
                <SearchBar />

                <TabPanel />
              </Stack>
            )}
            {!programValid && <Typography>The Star Map blockchain program could not be found.</Typography>}
          </Box>

          <Box sx={{ p: 4, border: 1, borderRadius: 5, borderColor: 'divider' }}>
            <Typography variant="body1" component="div" gutterBottom>
              FAQ
            </Typography>

            <HelpAccordion />
          </Box>

          <Typography variant="h4" component="div" sx={{ pt: 5, textAlign: 'center' }}>
            ⭐
          </Typography>

          <Typography variant="body2" component="div" sx={{ pb: 3, textAlign: 'center' }}>
            Copyright 2022
          </Typography>
        </Stack>
      </Box>
    </Grid>
  );
};
