import { useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import { Transaction, Connection, PublicKey } from '@solana/web3.js';
import React, { useCallback, FC, useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import {
  deleteEscrowAccount,
  getEscrowAccount,
  getHashedName,
  RecordType,
  StarState,
  withdrawEscrow,
} from 'starmap-api';
import { base58tails, isDefault } from '../../utils/Util';
import {
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import { WRAPPED_SOL_MINT } from '../../context/AccountsContext';
import { useSendTransaction } from '../../utils/SendTransaction';

async function withdrawTokenInstruction(
  connection: Connection,
  walletPublicKey: PublicKey,
  sourcePubkey: PublicKey,
  destinationPubkey: PublicKey,
  tokenMint: PublicKey,
  index: number,
  amount: bigint,
  name: string,
  recordType: RecordType,
  transaction: Transaction
) {
  if (isDefault(sourcePubkey) || isDefault(destinationPubkey)) throw new Error('invalid src/dst');
  const associatedSourceTokenAddr = await getAssociatedTokenAddress(tokenMint, sourcePubkey, true);
  const associatedDestinationTokenAddr = await getAssociatedTokenAddress(tokenMint, destinationPubkey);
  const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);
  if (receiverAccount === null) {
    console.log('create destination token account');
    transaction.add(
      createAssociatedTokenAccountInstruction(
        walletPublicKey,
        associatedDestinationTokenAddr,
        destinationPubkey,
        tokenMint
      )
    );
  }
  console.log(
    `Transfer ${amount} tokens from ${base58tails(associatedSourceTokenAddr)} to ${base58tails(
      associatedDestinationTokenAddr
    )}`
  );
  transaction.add(
    await withdrawEscrow(
      name,
      recordType,
      walletPublicKey,
      index,
      amount,
      associatedSourceTokenAddr,
      associatedDestinationTokenAddr
    )
  );
  if (tokenMint.equals(WRAPPED_SOL_MINT)) {
    console.log('Unwrap sol');
    transaction.add(createCloseAccountInstruction(associatedDestinationTokenAddr, walletPublicKey, walletPublicKey));
  }
}

async function sendWithdrawEscrow(
  connection: Connection,
  wallet: WalletContextState,
  tokenMint: PublicKey,
  index: number,
  amount: bigint,
  name: string,
  recordType: RecordType
) {
  if (!wallet.publicKey) throw new Error('no wallet pubkey');
  const transaction = new Transaction();

  const escrow = await getEscrowAccount(connection, getHashedName(name), recordType, index);
  if (escrow == null) throw new Error('cant find escrow');

  console.log(
    `Withdrawing ${amount} tokens from escrow #${index}; name: ${name} type: ${recordType} to wallet ${base58tails(
      wallet.publicKey
    )}`
  );
  await withdrawTokenInstruction(
    connection,
    wallet.publicKey,
    escrow.address,
    wallet.publicKey,
    tokenMint,
    index,
    amount,
    name,
    recordType,
    transaction
  );

  console.log('send clean up escrow account transaction');
  transaction.add(await deleteEscrowAccount(name, recordType, wallet.publicKey, escrow));
  return wallet.sendTransaction(transaction, connection, { skipPreflight: true });
}

type Props = {
  name: string;
  recordType: RecordType;
  record: StarState;
  index: number;
  amount: bigint;
  disabled: boolean;
  mint: PublicKey;
  label: string;
  icon: any;
  onSend: (success: boolean) => void;
};

export const WithdrawButton: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { sendTransaction } = useSendTransaction();
  const [busy, setBusy] = useState(false);

  const handleSend = useCallback(async () => {
    console.log('Withdrawing...');
    setBusy(true);
    const promise = sendWithdrawEscrow(
      connection,
      wallet,
      props.mint,
      props.index,
      props.amount,
      props.name,
      props.recordType
    );
    const result = await sendTransaction(promise);
    setBusy(false);
    props.onSend(result);
  }, [connection, props, sendTransaction, wallet]);

  return (
    <React.Fragment>
      {!busy && (
        <IconButton disabled={props.disabled} onClick={handleSend} size="small">
          {props.icon}
        </IconButton>
      )}
      {busy && <CircularProgress size={29} />}
    </React.Fragment>
  );
};
