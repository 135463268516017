import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { ENV, TokenInfo, TokenListProvider } from '@solana/spl-token-registry';
import { PublicKey } from '@solana/web3.js';
import { useEnvironment } from './EnvironmentContext';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { bigintToFloat } from '../utils/Util';
import { useLocale } from './LocaleContext';

interface ITokenRegistryConfig {
  getTokenInfoFromSymbol: (symbol: string) => TokenInfo | undefined;
  getTokenInfoFromKey: (mint: string) => TokenInfo | undefined;
  getUiAmount: (mint: string, rawAmount: bigint) => number;
  getUiAmountLocalized: (mint: string, rawAmount: bigint) => string;
  tokenRegistry: TokenInfo[];
}

const TokenRegistryContext = createContext<ITokenRegistryConfig | null>(null);

// const SUPPORTED_TOKEN_LIST = ['SOL', 'USDC', 'USDT', 'ETH'];

function toSplEnv(env: WalletAdapterNetwork): ENV {
  switch (env) {
    case WalletAdapterNetwork.Mainnet:
      return ENV.MainnetBeta;
    case WalletAdapterNetwork.Testnet:
      return ENV.Testnet;
    case WalletAdapterNetwork.Devnet:
      return ENV.Devnet;
  }
}

export const TokenRegistryProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const envContext = useEnvironment();
  const chainId = toSplEnv(envContext.chainName);
  const [tokenRegistry, setTokens] = useState<TokenInfo[]>([]);
  const { localizedNumber } = useLocale();

  const getTokenInfoFromSymbol = (symbol: string) => tokenRegistry.find(({ symbol: x }) => x === symbol);
  const getTokenInfoFromKey = useCallback(
    (key: string) => tokenRegistry.find(({ address: x }) => x === key),
    [tokenRegistry]
  );

  useEffect(() => {
    (async () => {
      const list = (await new TokenListProvider().resolve()).filterByChainId(chainId).getList();
      // const filteredList = list.filter(({ symbol }) => SUPPORTED_TOKEN_LIST.includes(symbol));
      const filteredList = list;

      if (chainId === ENV.Devnet) {
        filteredList.push({
          address: PublicKey.default.toString(),
          chainId,
          decimals: 9,
          name: 'Test',
          symbol: 'TEST',
        });
      }

      filteredList.sort(({ symbol: a }, { symbol: b }) => a.localeCompare(b));
      setTokens(filteredList);
    })();
  }, [chainId]);

  const getUiAmount = useCallback(
    (mint: string, rawAmount: bigint) => {
      const info = getTokenInfoFromKey(mint);
      if (!info) return bigintToFloat(rawAmount);
      return bigintToFloat(rawAmount) / 10.0 ** info.decimals;
    },
    [getTokenInfoFromKey]
  );

  const getUiAmountLocalized = useCallback(
    (mint: string, rawAmount: bigint) => {
      return localizedNumber(getUiAmount(mint, rawAmount));
    },
    [getUiAmount, localizedNumber]
  );

  return (
    <TokenRegistryContext.Provider
      value={{
        getTokenInfoFromSymbol,
        getTokenInfoFromKey,
        getUiAmount,
        getUiAmountLocalized,
        tokenRegistry,
      }}
    >
      {children}
    </TokenRegistryContext.Provider>
  );
};

export const useTokenRegistry = (): ITokenRegistryConfig => {
  const context = useContext(TokenRegistryContext);
  if (!context) throw new Error('Missing token registry context');
  return context;
};
