import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RecordType, StarState } from 'starmap-api';
import { FC, useCallback, useMemo, useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WithdrawButton } from './WithdrawButton';
import { bigintToFloat } from '../../utils/Util';
import { PendingAccount, useNameRecord } from '../../context/NamesContext';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useLocale } from '../../context/LocaleContext';

type Props = {
  withdrawToSender: boolean;
  withdrawLabel: string;
};

function selfOwned(record: StarState, walletKey: PublicKey | null) {
  if (!walletKey || record.recordType == RecordType.Invalid || !record.owner) return false;
  return record.owner.equals(walletKey);
}

export const EscrowTable: FC<Props> = (props) => {
  const wallet = useWallet();
  const nameInfo = useNameRecord();
  const { localizedNumber } = useLocale();
  const isSelfOwned = useMemo(() => selfOwned(nameInfo.record, wallet.publicKey), [nameInfo.record, wallet.publicKey]);
  const filteredPendingAmounts = useMemo<PendingAccount[]>(() => {
    const walletPublicKey = wallet.publicKey || PublicKey.default;
    if (props.withdrawToSender) return nameInfo.pendingAccounts.filter((value) => value.sender.equals(walletPublicKey));
    return nameInfo.pendingAccounts;
  }, [nameInfo.pendingAccounts, props.withdrawToSender, wallet.publicKey]);

  const onCancel = useCallback(
    async (result: boolean) => {
      console.log(`cancel: ${result} `);
      if (result) nameInfo.refreshEscrowAccounts();
    },
    [nameInfo]
  );

  const withdrawAllowed = useCallback(
    (sender: PublicKey) =>
      (props.withdrawToSender && sender.equals(wallet.publicKey || PublicKey.default)) ||
      (!props.withdrawToSender && isSelfOwned),
    [isSelfOwned, props.withdrawToSender, wallet.publicKey]
  );

  function withdrawIcon(withdrawToSender: boolean) {
    return withdrawToSender ? <CancelIcon /> : <DownloadForOfflineIcon />;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Token</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">{props.withdrawLabel}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredPendingAmounts.map((row) => (
            <TableRow key={row.address.toBase58()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {' '}
                {row.tokenName}{' '}
              </TableCell>
              <TableCell align="right">{localizedNumber(row.uiAmount)}</TableCell>
              <TableCell align="right">
                <WithdrawButton
                  name={nameInfo.name}
                  recordType={nameInfo.recordType}
                  record={nameInfo.record}
                  index={row.index}
                  amount={row.amount}
                  disabled={!withdrawAllowed(row.sender)}
                  mint={row.mint}
                  label="Withdraw"
                  icon={withdrawIcon(props.withdrawToSender)}
                  onSend={onCancel}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
