import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { confirmTransaction } from './Util';
import { useConnection } from '@solana/wallet-adapter-react';
import { Environment, useEnvironment, useSolanaExplorerUrlSuffix } from '../context/EnvironmentContext';
import { Button } from '@mui/material';

export function useSendTransaction() {
  const { connection } = useConnection();
  const { environment } = useEnvironment();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  async function sendTransaction(
    signaturePromise: Promise<string>,
    onSuccess: undefined | ((string) => void) = undefined,
    onError: undefined | ((string) => void) = undefined
  ) {
    let id = enqueueSnackbar('Sending transaction...', {
      variant: 'info',
      persist: true,
    });
    setSending(true);
    try {
      const signature = await signaturePromise;
      closeSnackbar(id);
      id = enqueueSnackbar('Confirming transaction...', {
        variant: 'info',
        persist: true,
        action: <ViewTransactionOnExplorerButton signature={signature} env={environment} />,
      });
      await confirmTransaction(connection, signature);
      closeSnackbar(id);
      setSending(false);
      enqueueSnackbar('Transaction confirmed', {
        variant: 'success',
        autoHideDuration: 5000,
        action: <ViewTransactionOnExplorerButton signature={signature} env={environment} />,
      });
      if (onSuccess) {
        onSuccess(signature);
      }
      return true;
    } catch (e) {
      closeSnackbar(id);
      setSending(false);
      console.warn(e);
      const message = e instanceof Error ? e.message : JSON.stringify(e);
      enqueueSnackbar(message, { variant: 'error' });
      if (onError) {
        onError(e);
      }
      return false;
    }
  }

  return { sendTransaction, sending };
}

export function ViewTransactionOnExplorerButton(props: { signature: string; env: Environment }) {
  const urlSuffix = useSolanaExplorerUrlSuffix(props.env);
  return (
    <Button
      color="inherit"
      component="a"
      target="_blank"
      rel="noopener"
      href={`https://solscan.io/tx/${props.signature}` + urlSuffix}
    >
      View on Solscan
    </Button>
  );
}

export function useCallAsync() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return async function callAsync(
    promise: Promise<string>,
    progressMessage = 'Submitting...',
    successMessage = 'Success',
    onSuccess: undefined | ((string) => void) = undefined,
    onError: undefined | ((string) => void) = undefined
  ) {
    const id = enqueueSnackbar(progressMessage, {
      variant: 'info',
      persist: true,
    });
    try {
      const result = await promise;
      closeSnackbar(id);
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      console.warn(e);
      closeSnackbar(id);
      const message = e instanceof Error ? e.message : JSON.stringify(e);
      enqueueSnackbar(message, { variant: 'error' });
      if (onError) {
        onError(e);
      }
    }
  };
}
