import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
// cSpell:disable
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  BloctoWalletAdapter,
  MathWalletAdapter,
  Coin98WalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
// cSpell:enable
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { base58tails } from '../utils/Util';
import { envToSolanaEndpoint, useEnvironment } from './EnvironmentContext';

const WalletMonitor: FC<{ children: ReactNode }> = ({ children }) => {
  const wallet = useWallet();
  const intervalId = React.useRef(0);
  const walletKey = React.useRef(wallet.publicKey);

  const disconnectIfWalletMismatch = useCallback(() => {
    const realPubkey = (window as any)?.solana?.publicKey;
    if (realPubkey != null && walletKey.current != null && !realPubkey.equals(walletKey.current)) {
      console.log(
        `Detected wallet change; disconnecting. ${base58tails(realPubkey)} != ${base58tails(walletKey.current)}`
      );
      wallet.disconnect();
    }
  }, [wallet]);

  useEffect(() => {
    const walletPublicKey = wallet.publicKey;
    if (intervalId.current == 0 && wallet.connected && walletPublicKey) {
      console.log(`Connected; pubkey: ${base58tails(walletPublicKey)})`);
      walletKey.current = wallet.publicKey;
      intervalId.current = window.setInterval(() => disconnectIfWalletMismatch(), 500);
    } else if (intervalId.current != 0 && !wallet.connected) {
      console.log('Disconnected');
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
        intervalId.current = 0;
      }
    }
  }, [disconnectIfWalletMismatch, wallet.connected, wallet.publicKey, wallet]);

  return <React.Fragment>{children}</React.Fragment>;
};

export const WalletContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { chainName, environment } = useEnvironment();
  const endpoint = useMemo(() => envToSolanaEndpoint(environment), [environment]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      // cSpell:disable
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new BloctoWalletAdapter(),
      new MathWalletAdapter(),
      new Coin98WalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network: chainName }),
      new SolletExtensionWalletAdapter({ network: chainName }),
      // cSpell:enable
    ],
    [chainName]
  );

  const { enqueueSnackbar } = useSnackbar();
  const onError = useCallback(
    (error: WalletError) => {
      enqueueSnackbar(error.message ? `${error.name}: ${error.message}` : error.name, { variant: 'error' });
      console.error(error);
    },
    [enqueueSnackbar]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect>
        <WalletDialogProvider>
          <WalletMonitor>{children}</WalletMonitor>
        </WalletDialogProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
