import React, { FC } from 'react';
import { WalletContext } from './context/WalletConnection';
import { NavAppBar } from './components/Navbar/Navbar';
import { Page } from './components/Page/Page';
import { TokenRegistryProvider } from './context/TokenRegistry';
import { AccountsProvider } from './context/AccountsContext';
import { AppTheme } from './AppTheme';
import { NameRecordProvider } from './context/NamesContext';
import { EnvContextProvider } from './context/EnvironmentContext';
import { LocaleContextProvider } from './context/LocaleContext';

export const App: FC = () => {
  return (
    <AppTheme>
      <LocaleContextProvider>
        <EnvContextProvider>
          <WalletContext>
            <TokenRegistryProvider>
              <AccountsProvider>
                <NameRecordProvider>
                  <NavAppBar />
                  <Page />
                </NameRecordProvider>
              </AccountsProvider>
            </TokenRegistryProvider>
          </WalletContext>
        </EnvContextProvider>
      </LocaleContextProvider>
    </AppTheme>
  );
};
