import { Transaction, Connection, PublicKey, SystemProgram, LAMPORTS_PER_SOL, Keypair } from '@solana/web3.js';
import {
  createEscrowAccount,
  getHashedName,
  getNextAvailableEscrowAccount,
  RecordType,
  transferAndNotify,
  transferNativeAndNotify,
} from 'starmap-api';
import {
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
  createSyncNativeInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import { bigintToFloat, isDefault } from './Util';
import { WRAPPED_SOL_MINT } from '../context/AccountsContext';

export interface TransferContext {
  connection: Connection;
  name: string;
  type: RecordType;
  sendNotification: boolean;
  sourceWallet: PublicKey;
  destinationWallet: PublicKey;
  amount: number;
  txId: Keypair;
  mint: PublicKey;
}

export async function sendToken(context: TransferContext): Promise<Transaction> {
  if (isDefault(context.destinationWallet)) throw new Error('Cannot send to default pubkey');
  console.log(`Creating transaction to transfer ${context.amount} tokens to ${context.destinationWallet}`);
  const transaction = new Transaction();
  const associatedSourceTokenAddr = await getAssociatedTokenAddress(context.mint, context.sourceWallet);
  const associatedDestinationTokenAddr = await getAssociatedTokenAddress(context.mint, context.destinationWallet);
  const receiverAccount = await context.connection.getAccountInfo(associatedDestinationTokenAddr);
  if (receiverAccount === null) {
    transaction.add(
      createAssociatedTokenAccountInstruction(
        context.sourceWallet,
        associatedDestinationTokenAddr,
        context.destinationWallet,
        context.mint
      )
    );
  }

  if (context.sendNotification) {
    transaction.add(
      await transferAndNotify(
        context.type,
        context.sourceWallet,
        context.txId.publicKey,
        BigInt(context.amount),
        associatedSourceTokenAddr,
        associatedDestinationTokenAddr
      )
    );
  } else {
    transaction.add(
      createTransferInstruction(
        associatedSourceTokenAddr,
        associatedDestinationTokenAddr,
        context.sourceWallet,
        context.amount
      )
    );
  }

  return transaction;
}

export async function sendSol(context: TransferContext): Promise<Transaction> {
  if (isDefault(context.destinationWallet)) throw new Error('Cannot send to default pubkey');
  console.log(`Creating transaction to transfer ${context.amount} lamports to ${context.destinationWallet}`);

  const transaction = new Transaction();
  if (context.sendNotification) {
    transaction.add(
      await transferNativeAndNotify(
        context.type,
        context.sourceWallet,
        context.txId.publicKey,
        BigInt(context.amount),
        context.destinationWallet
      )
    );
  } else {
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: context.sourceWallet,
        toPubkey: context.destinationWallet,
        lamports: context.amount,
      })
    );
  }
  return transaction;
}

async function maybeWrapAdditionalSol(
  connection: Connection,
  sourceWallet: PublicKey,
  associatedSourceTokenAddr: PublicKey,
  amount: number,
  transaction: Transaction
) {
  let amountToWrap = 0.0;
  let createdNewWrapper = false;
  // Check if the sender's wallet has a wrapped SOL account
  const srcAccountInfo = await connection.getAccountInfo(associatedSourceTokenAddr);
  const nativeBalance = await connection.getBalance(sourceWallet);
  if (srcAccountInfo === null) {
    console.log(`Create source wrapped SOL token account and wrap ${amount / LAMPORTS_PER_SOL}`);
    transaction.add(
      createAssociatedTokenAccountInstruction(sourceWallet, associatedSourceTokenAddr, sourceWallet, WRAPPED_SOL_MINT)
    );
    amountToWrap = amount;
    createdNewWrapper = true;
  } else {
    // If there's not enough wrapped Sol, wrap some more
    const srcTokenAccountInfo = await getAccount(connection, associatedSourceTokenAddr);
    const wrappedBalance = srcTokenAccountInfo.amount;
    const requiredWrapped = amount;
    amountToWrap = requiredWrapped - bigintToFloat(wrappedBalance);
    console.log(`Need to wrap an additional ${amountToWrap / LAMPORTS_PER_SOL}`);
  }
  if (amountToWrap == 0) return createdNewWrapper;

  if (amountToWrap > nativeBalance)
    throw new Error('Insufficient combined native and wrapped Sol balances for transfer');

  console.log(`Wrapping additional ${amountToWrap} lamports (${amountToWrap / LAMPORTS_PER_SOL} sol)`);
  transaction.add(
    SystemProgram.transfer({
      fromPubkey: sourceWallet,
      toPubkey: associatedSourceTokenAddr,
      lamports: amountToWrap,
    })
  );
  console.log('create native');
  transaction.add(createSyncNativeInstruction(associatedSourceTokenAddr));
  return createdNewWrapper;
}

export async function sendEscrow(context: TransferContext): Promise<Transaction> {
  console.log(`Creating transaction to escrow ${context.amount} tokens to ${context.destinationWallet}`);
  const transaction = new Transaction();
  // Wrap SOL if needed
  const associatedSourceTokenAddr = await getAssociatedTokenAddress(context.mint, context.sourceWallet);
  let createdNewSolWrapper = false;
  if (context.mint.equals(WRAPPED_SOL_MINT)) {
    createdNewSolWrapper = await maybeWrapAdditionalSol(
      context.connection,
      context.sourceWallet,
      associatedSourceTokenAddr,
      context.amount,
      transaction
    );
  }
  // Create escrow
  const hashedName = getHashedName(context.name);
  const state = await getNextAvailableEscrowAccount(context.connection, hashedName, context.type);
  console.log(`Creating escrow ${state.prev_index} -> [${state.index}] -> ${state.next_index}`);
  transaction.add(await createEscrowAccount(context.name, context.type, context.sourceWallet, state, context.mint));

  console.log(`Sending ${context.amount} tokens of mint ${context.mint} to escrow`);
  const associatedDestinationTokenAddr = await getAssociatedTokenAddress(context.mint, state.address, true);
  const receiverAccountInfo = await context.connection.getAccountInfo(associatedDestinationTokenAddr);
  if (receiverAccountInfo === null) {
    transaction.add(
      createAssociatedTokenAccountInstruction(
        context.sourceWallet,
        associatedDestinationTokenAddr,
        state.address,
        context.mint
      )
    );
  }
  if (context.sendNotification) {
    transaction.add(
      await transferAndNotify(
        context.type,
        context.sourceWallet,
        context.txId.publicKey,
        BigInt(context.amount),
        associatedSourceTokenAddr,
        associatedDestinationTokenAddr
      )
    );
  } else {
    transaction.add(
      createTransferInstruction(
        associatedSourceTokenAddr,
        associatedDestinationTokenAddr,
        context.sourceWallet,
        context.amount
      )
    );
  }
  if (createdNewSolWrapper) {
    console.log('Clean up the temporary source wSOL token account');
    transaction.add(
      createCloseAccountInstruction(associatedSourceTokenAddr, context.sourceWallet, context.sourceWallet)
    );
  } else if (context.mint.equals(WRAPPED_SOL_MINT)) {
    console.log('Leaving the existing source wSOL token account');
  }

  return transaction;
}
