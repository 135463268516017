import { getHashedName, getNameAccountKey, NameRegistryState } from '@bonfida/spl-name-service';
import { Connection, PublicKey } from '@solana/web3.js';

export const SOL_TLD_AUTHORITY = new PublicKey('58PwtjSDuFHuUkYjH9BYnnQKHfwo9reZhC2zMJv9JPkx');

export const ROOT_TLD_AUTHORITY = new PublicKey('ZoAhWEqTVqHVqupYmEanDobY7dee5YKbQox9BNASZzU');

export async function bonfidaOwner(connection: Connection, fqdn: string) {
  const domainName = fqdn.slice(0, -4); // Without the .sol
  console.log('domainName:', domainName);

  // Step 1
  const hashedName = await getHashedName(domainName);

  // Step 2
  const domainKey = await getNameAccountKey(hashedName, undefined, SOL_TLD_AUTHORITY);

  // Step 3
  // The registry object contains all the info about the domain name (cf struct above)
  // The NFT owner is of type PublicKey | undefined
  const { registry, nftOwner } = await NameRegistryState.retrieve(connection, domainKey);
  if (nftOwner != undefined) return nftOwner;
  return registry.owner;
}
