import React, { useCallback, FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RecordType, StarState } from 'starmap-api';
import { DeleteButton } from './DeleteButton';
import { useWallet } from '@solana/wallet-adapter-react';
import { isSelfOwned } from '../../utils/Util';
import { TransferButton } from './TransferButton';
import { useNameRecord } from '../../context/NamesContext';
import { NotifyPreferenceToggle } from './NotifyPreferenceToggle';

export const TabManage: FC = () => {
  const wallet = useWallet();
  const nameInfo = useNameRecord();

  const selfOwned = isSelfOwned(nameInfo.record, wallet.publicKey);

  let invite: string;
  if (nameInfo.recordType == RecordType.Invalid) {
    invite = 'Enter an alias above';
  } else if (selfOwned) {
    invite = '';
  } else {
    invite = 'You can only manage aliases that you own.';
  }

  return (
    <Stack spacing={2}>
      <Typography sx={{ color: 'text.primary' }}>{invite}</Typography>

      <TransferButton
        name={nameInfo.name}
        recordType={nameInfo.recordType}
        record={nameInfo.record}
        onTransfer={nameInfo.onBlockchainUpdate}
      />
      <NotifyPreferenceToggle
        name={nameInfo.name}
        recordType={nameInfo.recordType}
        record={nameInfo.record}
        onChange={nameInfo.onBlockchainUpdate}
      />

      <DeleteButton
        name={nameInfo.name}
        recordType={nameInfo.recordType}
        record={nameInfo.record}
        onDelete={nameInfo.onBlockchainUpdate}
      />
    </Stack>
  );
};
