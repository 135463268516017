import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import React, { FC, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { Environment, useEnvironment } from '../../context/EnvironmentContext';
import { useNameRecord } from '../../context/NamesContext';
import { useSnackbar } from 'notistack';

export const Airdrop: FC = () => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const { onBlockchainUpdate } = useNameRecord();
  const { enqueueSnackbar } = useSnackbar();
  const [isAirdrop, setAirdrop] = useState<boolean | null>(null);
  const { environment } = useEnvironment();
  const hidden = environment === Environment.mainnet;

  const airdropSol = useCallback(async () => {
    if (!publicKey) {
      throw new WalletNotConnectedError() && alert('Wallet not connected');
    }

    try {
      const airdropAmount = 1;
      console.log(`Air dropping... ${airdropAmount} SOL`);
      const signature = await connection.requestAirdrop(
        publicKey,
        airdropAmount * LAMPORTS_PER_SOL // max airdrop at max 2 SOL in one transaction
      );
      const error = (await connection.confirmTransaction(signature, 'confirmed')).value.err;
      if (error != null) {
        throw new Error(JSON.stringify(error));
      }
      console.log('Drop successful!');
      onBlockchainUpdate();
      setAirdrop(true);
      setTimeout(() => {
        setAirdrop(null);
      }, 2000);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Airdrop failed: ${err}.`, { variant: 'error' });
      setAirdrop(false);
    }
  }, [publicKey, connection, onBlockchainUpdate, enqueueSnackbar]);

  return (
    <React.Fragment>
      <div>
        {!hidden && (
          <Button onClick={airdropSol} variant="contained" sx={{ m: 1 }}>
            {isAirdrop === true ? 'Success' : isAirdrop === false ? 'Error' : 'Airdrop SOL'}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};
