import React, { useCallback, FC, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getEscrowCostLamports, getNotifyCostLamports, RecordType } from 'starmap-api';
import * as StarmapApi from 'starmap-api';
import { base58tails, isDefault, isSelfOwned } from '../../utils/Util';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { useAccounts, WRAPPED_SOL_MINT } from '../../context/AccountsContext';
import { useTokenRegistry } from '../../context/TokenRegistry';
import { SendButton } from './SendButton';
import { EscrowTable } from './EscrowTable';
import { INameInfo, PendingAccount, useNameRecord } from '../../context/NamesContext';
import { useLocale } from '../../context/LocaleContext';

type StatusFlags = {
  disconnected: boolean;
  selfOwned: boolean;
  invalidType: boolean;
  lowBalance: boolean;
  invalidDestination: boolean;
  invalidSendAmount: boolean;
  noSendAmount: boolean;
  overdraft: boolean;
};

function statusMessage(statusFlags: StatusFlags, nameInfo: INameInfo) {
  if (statusFlags.disconnected) return 'Connect a wallet to continue';
  if (statusFlags.selfOwned) return 'You own this address';
  if (statusFlags.invalidType) return 'Enter an address above';
  if (statusFlags.lowBalance) return 'Insufficient SOL in your wallet';
  if (statusFlags.invalidSendAmount) return 'Invalid amount';
  if (statusFlags.overdraft) return 'Insufficient funds';
  if (statusFlags.invalidDestination || !nameInfo.record.isAssignedAndValid)
    return `Send a payment to: ${nameInfo.name}`;
  return `Send a payment to: ${base58tails(nameInfo.record.owner)}`;
}

export const TabSend: FC = () => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const accounts = useAccounts();
  const { localizedNumber, numberParser } = useLocale();
  const nameInfo = useNameRecord();
  const { getTokenInfoFromKey, getUiAmount } = useTokenRegistry();

  const [selectedToken, setSelectedToken] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [amount, setAmount] = useState(0.0);
  const [amountStr, setAmountStr] = useState('');
  const [timerId, setTimerId] = React.useState(0);
  const [nativeBalance, setNativeBalance] = React.useState(0);
  const [enableNotify, setEnableNotify] = React.useState(true);
  const [notifyProgramFee, setNotifyProgramFee] = useState<StarmapApi.CostInfo>({ fee: 0, deposit: 0 });
  const [escrowProgramFee, setEscrowProgramFee] = useState<StarmapApi.CostInfo>({ fee: 0, deposit: 0 });

  const isEscrow = React.useMemo(() => !nameInfo.record.isAssignedAndValid, [nameInfo.record.isAssignedAndValid]);
  const isNativeToken = React.useMemo(() => selectedToken == WRAPPED_SOL_MINT.toBase58(), [selectedToken]);

  const filteredPendingAmounts = useMemo<PendingAccount[]>(() => {
    const walletPublicKey = wallet.publicKey || PublicKey.default;
    return nameInfo.pendingAccounts.filter((value) => value.sender.equals(walletPublicKey));
  }, [nameInfo.pendingAccounts, wallet.publicKey]);

  const allowedTokenAndAmount = React.useMemo(() => {
    const tokenInfo = verifyToken(selectedToken);
    return tokenInfo != undefined && allowedTokenAmount(tokenInfo, amount);
  }, [amount, selectedToken]);

  const allowNotify = React.useMemo(() => {
    const isEmail = nameInfo.recordType == RecordType.Email;
    const userDisabled = nameInfo.record.flags.disable_notifications;
    return isEmail && allowedTokenAndAmount && !userDisabled;
  }, [allowedTokenAndAmount, nameInfo.record.flags.disable_notifications, nameInfo.recordType]);

  const notifyCheckboxLabel = React.useMemo(() => {
    if (nameInfo.recordType != RecordType.Email) {
      return 'Only email is supported at the moment';
    } else if (nameInfo.record.flags.disable_notifications) {
      return 'The recipient has disabled notifications';
    } else if (!allowedTokenAndAmount) {
      return 'Notifications not supported for this token or amount (< $1 USD)';
    } else {
      return 'Notify the recipient';
    }
  }, [allowedTokenAndAmount, nameInfo.record.flags.disable_notifications, nameInfo.recordType]);

  const nonzeroBalanceTokens = useMemo(
    () => Object.keys(accounts.balances).filter((value: string) => accounts.getAmount(value) > 0),
    [accounts]
  );

  const selectedTokenBalance = useMemo(() => {
    if (!wallet.publicKey) {
      return 0;
    }
    if (selectedToken == '' && Object.keys(accounts.balances).includes(WRAPPED_SOL_MINT.toString())) {
      const tokenAddressString = WRAPPED_SOL_MINT.toString();
      setSelectedToken(WRAPPED_SOL_MINT.toString());
      return accounts.getUIAmount(tokenAddressString);
    } else if (selectedToken != '') {
      return accounts.getUIAmount(selectedToken);
    } else {
      return 0;
    }
  }, [accounts, selectedToken, wallet.publicKey]);

  const totalFee = useMemo(() => {
    const applicableNotifyFee = allowNotify && enableNotify ? notifyProgramFee.fee : 0;
    const applicableEscrowFee = isEscrow ? escrowProgramFee.fee : 0;
    return getUiAmount(WRAPPED_SOL_MINT.toBase58(), BigInt(applicableNotifyFee + applicableEscrowFee));
  }, [allowNotify, enableNotify, escrowProgramFee.fee, getUiAmount, isEscrow, notifyProgramFee.fee]);

  const totalDeposit = useMemo(() => {
    const applicableNotifyDeposit = allowNotify && enableNotify ? notifyProgramFee.deposit : 0;
    const applicableEscrowDeposit = isEscrow ? escrowProgramFee.deposit : 0;
    return getUiAmount(WRAPPED_SOL_MINT.toBase58(), BigInt(applicableNotifyDeposit + applicableEscrowDeposit));
  }, [allowNotify, enableNotify, escrowProgramFee.deposit, getUiAmount, isEscrow, notifyProgramFee.deposit]);

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      const notifyFeeRes = await getNotifyCostLamports(connection, nameInfo.recordType);
      const escrowFeeRes = await getEscrowCostLamports(connection);
      if (!active) {
        return;
      }
      setNotifyProgramFee(notifyFeeRes);
      setEscrowProgramFee(escrowFeeRes);
    }
  }, [connection, nameInfo.recordType]);

  useEffect(() => {
    const checks = async () => {
      const uncheckedAmount = numberParser(amountStr);
      const newNativeBalance = wallet.publicKey
        ? getUiAmount(WRAPPED_SOL_MINT.toBase58(), BigInt(await connection.getBalance(wallet.publicKey)))
        : 0;
      setNativeBalance(newNativeBalance);
      const status: StatusFlags = {
        disconnected: !wallet || !wallet.connected || !wallet.publicKey,
        selfOwned: isSelfOwned(nameInfo.record, wallet.publicKey),
        invalidType: nameInfo.recordType == RecordType.Invalid,
        lowBalance: newNativeBalance < 0.001,
        invalidDestination: isDefault(nameInfo.record.owner),
        invalidSendAmount: amountStr.length > 0 && (Number.isNaN(uncheckedAmount) || uncheckedAmount < 0),
        noSendAmount: amountStr.length == 0 || uncheckedAmount == 0,
        overdraft: amountStr.length > 0 && selectedTokenBalance - amount < 0,
      };
      setDisabled(
        status.invalidType ||
          status.selfOwned ||
          status.lowBalance ||
          status.invalidSendAmount ||
          status.overdraft ||
          status.noSendAmount
      );
      setMessage(statusMessage(status, nameInfo));
    };
    checks();
  }, [amount, numberParser, amountStr, connection, nameInfo, selectedTokenBalance, wallet, getUiAmount]);

  useEffect(() => {
    if (!timerId && wallet.connected) {
      setTimerId(window.setInterval(accounts.refreshTokenAccounts, 2000));
    } else if (timerId && !wallet.connected) {
      if (timerId) {
        window.clearInterval(timerId);
        setTimerId(0);
      }
    }
    return () => window.clearInterval(timerId);
  }, [timerId, wallet.connected, wallet.publicKey, accounts.refreshTokenAccounts]);

  const onTokenChange = useCallback((tokenAddressString: string) => {
    setSelectedToken(tokenAddressString);
  }, []);

  const onAmountChange = useCallback(
    async (input: string) => {
      const amount = numberParser(input);
      if (Number.isNaN(amount) || amount < 0) {
        setAmount(0.0);
        return;
      }
      setAmount(amount);
    },
    [numberParser]
  );

  const onNotifyPreference = useCallback((event, newValue) => {
    setEnableNotify(newValue);
  }, []);

  const nameForAccount = (mint: string) => {
    const info = getTokenInfoFromKey(mint);
    if (info != undefined && info.symbol != undefined) return info.symbol;
    return mint.substring(0, 6);
  };

  const decimals = (mint: string): number | undefined => {
    if (mint.length == 0 || !Object.keys(accounts.balances).includes(mint)) return undefined;
    const tokenInfo = getTokenInfoFromKey(mint.toString());
    if (!tokenInfo) return undefined;
    return tokenInfo.decimals;
  };

  const onSend = useCallback(
    async (success: boolean) => {
      console.log(`Send success: ${success}`);
      nameInfo.refreshEscrowAccounts();
    },
    [nameInfo]
  );

  return (
    <Stack spacing={2}>
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
      <Grid container alignItems="center" sx={{ py: 0 }}>
        <FormControl sx={{ pr: 2 }}>
          <InputLabel id="token-select-label">Token</InputLabel>
          <Select
            labelId="token-select-label"
            id="token-select"
            value={selectedToken}
            label="selectedToken"
            onChange={(e) => {
              onTokenChange(e.target.value);
            }}
            sx={{ minWidth: 80 }}
          >
            {nonzeroBalanceTokens.map((token) => (
              <MenuItem key={token} value={token}>
                {nameForAccount(token)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Amount"
          value={amountStr}
          onChange={(e) => {
            setAmountStr(e.target.value);
            onAmountChange(e.target.value);
          }}
          sx={{ pr: 2, width: 150 }}
        />
        <SendButton
          sendNotification={allowNotify && enableNotify}
          disabled={disabled}
          uiAmount={amount}
          decimals={decimals(selectedToken)}
          mint={selectedToken}
          onSend={onSend}
        />
      </Grid>
      <FormGroup>
        <FormControlLabel
          disabled={!allowNotify}
          control={
            <Checkbox
              checked={enableNotify}
              onChange={onNotifyPreference}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Notify recipient"
        />
        {!allowNotify && <FormHelperText>{notifyCheckboxLabel}</FormHelperText>}
      </FormGroup>
      {isNativeToken && nativeBalanceInfo()}
      {!isNativeToken && selectedToken != '' && splBalanceInfo()}
      {filteredPendingAmounts.length > 0 && (
        <Box>
          <Typography variant="h5" sx={{ pb: 2 }}>
            Pending Transfers
          </Typography>
          <EscrowTable withdrawToSender={true} withdrawLabel="Cancel" />
        </Box>
      )}
    </Stack>
  );

  function nativeBalanceInfo() {
    return (
      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography variant="body2" component="div">
            Available: {localizedNumber(selectedTokenBalance)}
          </Typography>
          <Typography variant="body2" component="div">
            Amount to send: {localizedNumber(amount)}
          </Typography>
          <Typography variant="body2" component="div">
            Fee: {localizedNumber(totalFee)}
          </Typography>
          <Typography variant="body2" component="div">
            Final balance: {localizedNumber(selectedTokenBalance - amount - totalFee)}
          </Typography>
        </Stack>
        <Stack>
          {((allowNotify && enableNotify) || isEscrow) && (
            <Typography variant="body2" component="div">
              Deposit: {localizedNumber(totalDeposit)}
            </Typography>
          )}
          {((allowNotify && enableNotify) || isEscrow) && (
            <Typography variant="body2" component="div">
              Temporary balance: {localizedNumber(selectedTokenBalance - amount - totalFee - totalDeposit)}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }

  function splBalanceInfo() {
    return (
      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography variant="body1" component="div" sx={{ pt: 1 }}>
            {nameForAccount(selectedToken)}
          </Typography>
          <Typography variant="body2" component="div">
            Available: {localizedNumber(selectedTokenBalance)}
          </Typography>
          <Typography variant="body2" component="div">
            Amount to send: {localizedNumber(amount)}
          </Typography>
          <Typography variant="body2" component="div">
            Final token balance: {localizedNumber(selectedTokenBalance - amount)}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body1" component="div" sx={{ pt: 1 }}>
            SOL
          </Typography>
          <Typography variant="body2" component="div">
            Available: {localizedNumber(nativeBalance)}
          </Typography>
          <Typography variant="body2" component="div">
            Fee: {localizedNumber(totalFee)}
          </Typography>
          {((allowNotify && enableNotify) || isEscrow) && (
            <Typography variant="body2" component="div">
              Deposit: {localizedNumber(totalDeposit)}
            </Typography>
          )}
          {((allowNotify && enableNotify) || isEscrow) && (
            <Typography variant="body2" component="div">
              Temporary balance: {localizedNumber(nativeBalance - totalFee - totalDeposit)}
            </Typography>
          )}
          {((allowNotify && enableNotify) || isEscrow) && (
            <Typography variant="body2" component="div">
              Final balance: {localizedNumber(nativeBalance - totalFee)}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }
};

interface TokenInfo {
  decimals: number;
  minUiAmount: number;
  tokenName: string;
}

function verifyToken(mint: string): TokenInfo | undefined {
  let minUiAmount: number;
  let decimals: number;
  let tokenName: string;
  if (mint == 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v') {
    decimals = 6;
    minUiAmount = 1.0;
    tokenName = 'USDC';
  } else if (mint == 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB') {
    decimals = 6;
    minUiAmount = 1.0;
    tokenName = 'USDT';
  } else if (mint == 'So11111111111111111111111111111111111111112') {
    decimals = 9;
    minUiAmount = 0.01;
    tokenName = 'SOL';
  } else if (mint == '2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk') {
    decimals = 6;
    minUiAmount = 0.001;
    tokenName = 'ETH';
  } else if (mint == '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E') {
    decimals = 6;
    minUiAmount = 0.0001;
    tokenName = 'BTC';
  } else {
    return undefined;
  }
  return { decimals, minUiAmount, tokenName };
}

function allowedTokenAmount(tokenInfo: TokenInfo, uiAmount: number) {
  return uiAmount >= tokenInfo.minUiAmount;
}
