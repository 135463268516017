import { useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Transaction, Connection } from '@solana/web3.js';
import React, { useCallback, FC, useState, useMemo } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { authorizeVerificationPayment, RecordType, StarState } from 'starmap-api';
import axios from 'axios';
import { isSelfOwned, recordTypeToTwilioType } from '../../utils/Util';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { useEnvironment } from '../../context/EnvironmentContext';
import { useSendTransaction } from '../../utils/SendTransaction';
import { useLocale } from '../../context/LocaleContext';

async function sendAuthorize(
  connection: Connection,
  wallet: WalletContextState,
  name: string,
  type: RecordType,
  routingInfoSize: number
) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  console.log('Creating name record and authorizing verification on blockchain');
  const instr = await authorizeVerificationPayment(name, type, wallet.publicKey, routingInfoSize);
  const trans = new Transaction();
  trans.add(instr);
  return wallet.sendTransaction(trans, connection);
}

type Props = {
  name: string;
  recordType: RecordType;
  record: StarState;
  lowBalance: boolean;
  onClaim: (success: boolean) => void;
};

function startIcon(busy: boolean, record: StarState) {
  if (busy) return <CircularProgress color="inherit" size={19} />;
  return record.isReadyToAssign || record.isAssignedAndValid ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
}

export const ClaimButton: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { twilioEndpoint } = useEnvironment();
  const { userBrowserLanguage } = useLocale();
  const { sendTransaction } = useSendTransaction();
  const { enqueueSnackbar } = useSnackbar();
  const [busy, setBusy] = useState(false);
  const selfOwned = isSelfOwned(props.record, wallet.publicKey);
  const buttonDisabled = useMemo(
    () => ![RecordType.Email, RecordType.Phone].includes(props.recordType) || selfOwned || props.lowBalance,
    [props.lowBalance, props.recordType, selfOwned]
  );

  const handleClaim = useCallback(async () => {
    if (props.recordType === RecordType.Invalid) {
      return;
    }
    setBusy(true);
    let success = true;
    let message = '';
    if (props.record.isAuthorized) {
      console.log('Already authorized');
    } else {
      try {
        const promise = sendAuthorize(connection, wallet, props.name, props.recordType, 0);
        success = await sendTransaction(promise);
      } catch (e: any) {
        message = `Claim request failed: ${e}.`;
        success = false;
      }
    }

    if (success) {
      try {
        console.log('Requesting server to send claim code');
        await axios.post(`${twilioEndpoint}/start-verify`, {
          to: props.name,
          channel: recordTypeToTwilioType(props.recordType),
          locale: userBrowserLanguage,
        });
        message = `Successfully requested claim`;
      } catch (e: any) {
        message = `Claim request failed: ${e}.`;
        success = false;
      }
    }
    setBusy(false);
    console.log(message);
    enqueueSnackbar(message, { variant: success ? 'success' : 'error' });
    props.onClaim(success);
  }, [connection, enqueueSnackbar, props, sendTransaction, twilioEndpoint, userBrowserLanguage, wallet]);

  return (
    <Box sx={{ alignItems: 'center' }}>
      {startIcon(busy, props.record)}
      <Button disabled={buttonDisabled} onClick={handleClaim} variant="contained" sx={{ ml: 2 }}>
        Start verification
      </Button>
    </Box>
  );
};
