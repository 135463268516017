import React, { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Airdrop } from '../Airdrop/Airdrop';
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import EnvMenu from './EnvMenu';

export const NavAppBar: FC = (props) => {
  return (
    <React.Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
            Star Map
          </Typography>
          <Airdrop />
          <EnvMenu />
          <Box sx={{ maxWidth: 5, minWidth: 5 }} />
          <WalletMultiButton />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
