import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '@mui/material';

export default function HelpAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography color="text.primary">What is Star Map?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography display="inline" color="text.secondary">
            {'Star Map is an on-chain '}
          </Typography>
          <Link display="inline" href="https://solana.com" target="_blank" rel="noopener">
            Solana
          </Link>
          <Typography display="inline" color="text.secondary">
            {' database that maps public identifiers to wallet addresses.'}
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Supported identifiers currently include phone numbers, email addresses, and Bonfida domains.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Star Map also includes a payment system that enables you to send money to people without knowing their
            wallet address, and even to people who don't yet have a Solana Wallet!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">Why use Star Map?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            Star Map enables you to send money on Solana without knowing a recipient's wallet public key.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            If the recipient has verified their wallet address on Star Map, you can directly send SOL or tokens. Use the
            Star Map transfer screen, or copy the recipient's wallet address into your wallet's transfer screen.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            If the recipient hasn't verified their wallet on Star Map, you can 'Send via Star Map'. This means that the
            funds are held in escrow until claimed. As long as the recipient has not verified their wallet, the sender
            can easily cancel the pending transfer.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">Will the recipient be notified of payment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            A checkbox in the SEND tab enables sending notifications to supported addresses, tokens, and amounts.
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            If notifications are not supported for a "via Starmap" transfer, please let the recipient know to visit
            https://starmap.network to claim their funds.
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            Notifications for small amounts aren't supported in order to prevent spam and abuse.
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            We're working hard to support more new communication channels in the future.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">How to I claim funds sent to me via Star Map?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" variant="body1">
            First, in the search box, enter the email or phone number that funds were sent to. Check that you see the
            pending funds on the CLAIM tab.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ py: 2 }}>
            See em? Great! You'll need a wallet and a little SOL to proceed.
          </Typography>
          <Typography color="text.secondary" display="inline">
            If you're new to Solana, we recommend installing{' '}
          </Typography>
          <Link display="inline" href="https://phantom.app" target="_blank" rel="noopener">
            Phantom Wallet
          </Link>
          <Typography color="text.secondary" display="inline">
            . You'll need a about 0.01 SOL to pay for fees, which you can{' '}
          </Typography>
          <Link display="inline" href="https://www.moonpay.com/buy/sol" target="_blank" rel="noopener">
            buy
          </Link>
          <Typography color="text.secondary" display="inline" sx={{ pt: 2 }}>
            {' '}
            from within the Phantom App.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 2 }}>
            Now that you're set up, here's how to claim your funds:
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 2 }}>
            1. On CLAIM tab, click Claim and accept the wallet transaction.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 2 }}>
            2. Enter the verification code that you received and click Verify.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 2 }}>
            3. Click the button to the right of each pending transfer to withdraw the funds.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">What can I do with money on Solana?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" display="inline">
            1. You can pay merchants online and in person using{' '}
          </Typography>
          <Link display="inline" href="https://solanapay.com" target="_blank" rel="noopener">
            Solana Pay
          </Link>
          <Typography color="text.secondary" sx={{ pt: 1, pb: 1 }}>
            2. You can send money to friends and family. Star Map makes it easy!
          </Typography>
          <Typography color="text.secondary" display="inline">
            3. You can lend it to earn interest. Sites such as{' '}
          </Typography>
          <Link display="inline" href="https://solend.fi" target="_blank" rel="noopener">
            Solend
          </Link>
          <Typography color="text.secondary" display="inline" sx={{ pt: 1 }}>
            {' '}
            offer lending yield.
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            4. You can stake SOL directly in Phantom to earn SOL.
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            5. You can buy digital artwork ("NFTs").
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">How do I withdraw money to my bank account?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            Until MoonPay supports withdraws, you'll need to setup an account with a broker (eg. Coinbase, Kraken, FTX,
            Binance).
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            After setting up a broker account, you can transfer funds to your account at the brokerage.
          </Typography>
          <Typography color="text.secondary" sx={{ pt: 1 }}>
            Finally, you can sell/convert tokens to your preferred currency and withdraw.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">What are the fees?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">Almost none!</Typography>
          <Typography color="text.primary" sx={{ pt: 2 }}>
            Temporary deposits include:
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Send via Star Map: 0.00484 SOL
          </Typography>
          <Typography color="text.secondary" variant="body2" sx={{ pl: 2 }}>
            The Solana blockchain requires this deposit to store data on the blockchain and Starmap refunds this deposit
            upon completion of the transfer.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Verify: 0.00156 SOL
          </Typography>
          <Typography color="text.secondary" variant="body2" sx={{ pl: 2 }}>
            The Solana blockchain requires this deposit to store data on the blockchain and Starmap refunds this deposit
            upon deletion of the verification record.
          </Typography>
          <Typography color="text.primary" variant="body1" sx={{ pt: 2 }}>
            Non-refundable fees include:
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Verify Phone Number: 0.001 SOL
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Verify Email Address: 0.0001 SOL
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Send to Email with Notification: 0.0001 SOL
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Solana Transaction Costs: approx. 0.00001 SOL
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">Why is there a fee?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            Temporary deposits are Solana 'rent' used to store the data in the escrow and verify records. Solana refunds
            the rent upon data deletion, and Star Map currently refunds this in full.
          </Typography>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            Non-refundable fees cover backend server costs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <Typography color="text.primary">Are there privacy risks?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            Star Map creates a public link between your personal identifier and your wallet address. Because all
            transactions on Solana are currently public, a Star Map record links your transaction history and balances
            to your public identity.
          </Typography>
          <Typography color="text.secondary" variant="body1">
            To protect your privacy, either:
          </Typography>
          <Typography color="text.secondary" variant="body1">
            (a) Fund a new "public" wallet from a centralized exchange (eg. CoinBase, FTX, Kraken, etc.) and use this
            'public' wallet for Star Map.
          </Typography>
          <Typography color="text.secondary" variant="body1">
            (b) Use a secondary email address that isn't publicly linked to your personal identity.
          </Typography>
          <Typography color="text.secondary" variant="body1">
            (c) Create a Stars entry (eg. *MrPotatoHead) that doesn't contain personal identifiers.
          </Typography>
          <Typography color="text.secondary" variant="body1">
            (d) Wait for a SPL token 2022 confidential transaction amounts and balances.
          </Typography>
          <Typography color="text.secondary" variant="body1">
            (e) Wait for mixer (Lava Cash, Light Protocol, Elusiv, Railgun). A 'public' wallet will likely be necessary
            to create and manage the private Star Map record. Some routing info will redirect transfers to your mixer
            account.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">Can I transfer my record?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            Yes, a TRANSFER button can be found in the MANAGE tab. Remember to triple-check the destination address
            (perhaps by sending some lamports) and remember that blockchain history is public. This is currently free
            but we may add a fee to discourage scalping.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color="text.primary">Can I delete my record?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary">
            Yes, a DELETE button can be found in the MANAGE tab. However, a history of the creation and deletion of the
            entry will remain on the blockchain ledger (that's how blockchains work!), so you will need to create a new
            wallet address to anonymize your future transactions and holdings.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
