import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Transaction, PublicKey, LAMPORTS_PER_SOL, Keypair } from '@solana/web3.js';
import React, { useCallback, FC, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { RecordType, StarState } from 'starmap-api';
import { recordTypeToTwilioType } from '../../utils/Util';
import axios from 'axios';
import { WRAPPED_SOL_MINT } from '../../context/AccountsContext';
import SendIcon from '@mui/icons-material/Send';
import { useSnackbar } from 'notistack';
import { useEnvironment } from '../../context/EnvironmentContext';
import { useSendTransaction, ViewTransactionOnExplorerButton } from '../../utils/SendTransaction';
import { useLocale } from '../../context/LocaleContext';
import { sendEscrow, sendSol, sendToken, TransferContext } from '../../utils/Transfer';
import { useNameRecord } from '../../context/NamesContext';

function inputError(props: Props) {
  if (!props.mint.length) {
    console.log("Can't send; no mint supplied");
    props.onSend(false);
    return true;
  }
  if (props.decimals == undefined) {
    console.log('Decimals cannot be undefined.');
    props.onSend(false);
    return true;
  }
  const mint = new PublicKey(props.mint);
  const base = 10 ** props.decimals;
  if (mint.equals(WRAPPED_SOL_MINT) && LAMPORTS_PER_SOL != base) {
    console.log('Decimals mismatch.');
    props.onSend(false);
    return true;
  }
  return false;
}

type Props = {
  sendNotification: boolean;
  disabled: boolean;
  uiAmount: number;
  decimals: number | undefined;
  mint: string;
  onSend: (success: boolean) => void;
};

function icon(busy: boolean) {
  return busy ? <CircularProgress color="inherit" size={19} /> : <SendIcon />;
}

export const SendButton: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const nameInfo = useNameRecord();
  const { environment, twilioEndpoint } = useEnvironment();
  const { userBrowserLanguage } = useLocale();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [busy, setBusy] = useState(false);
  const { sendTransaction } = useSendTransaction();

  const handleSend = useCallback(async () => {
    if (inputError(props)) return;

    if (!wallet.publicKey) throw new Error("Couldn't create escrow; no wallet public key");

    setBusy(true);
    const mint = new PublicKey(props.mint);
    const base = 10 ** (props.decimals || 0);
    const baseAmount = props.uiAmount * base;
    const nativeSolTransaction = nameInfo.record.isAssignedAndValid && mint.equals(WRAPPED_SOL_MINT);
    const context: TransferContext = {
      connection,
      name: nameInfo.name,
      type: nameInfo.recordType,
      sendNotification: props.sendNotification,
      sourceWallet: wallet.publicKey,
      destinationWallet: nameInfo.record.owner,
      amount: baseAmount,
      txId: Keypair.generate(),
      mint,
    };
    let transaction: Transaction;
    try {
      if (nativeSolTransaction) {
        transaction = await sendSol(context);
      } else if (nameInfo.record.isAssignedAndValid) {
        transaction = await sendToken(context);
      } else {
        transaction = await sendEscrow(context);
      }
    } catch (e) {
      const message = `Error creating transaction: ${e}`;
      console.log(message);
      enqueueSnackbar(message, { variant: 'error' });
      props.onSend(false);
      setBusy(false);
      return;
    }

    const signers = context.sendNotification ? [context.txId] : [];
    const promise = wallet.sendTransaction(transaction, context.connection, { signers });

    let txSignature = '';
    const onSuccess = (sig: string) => {
      txSignature = sig;
    };
    let result = await sendTransaction(promise, onSuccess);
    if (result && context.sendNotification) {
      console.log('Sending notification to recipient');
      let id = enqueueSnackbar('Requesting notification...', {
        variant: 'info',
        persist: true,
      });
      try {
        const response = await axios.post(`${twilioEndpoint}/notify`, {
          to: nameInfo.name,
          from: '',
          channel: recordTypeToTwilioType(nameInfo.recordType),
          txId: context.txId.publicKey.toBase58(),
          txSignature: txSignature,
          locale: userBrowserLanguage,
        });
        closeSnackbar(id);
        id = enqueueSnackbar('Notification sent. Waiting for blockchain confirmation...', {
          variant: 'info',
          persist: true,
          action: <ViewTransactionOnExplorerButton signature={response.data.signature} env={environment} />,
        });
      } catch (e) {
        console.log(JSON.stringify(e));
        result = false;
      }
      const message = `${result ? 'Successfully sent' : 'Failed to send'} notification to recipient.`;
      console.log(message);
      closeSnackbar(id);
      enqueueSnackbar(message, { variant: result ? 'success' : 'error', autoHideDuration: 3000 });
    }

    props.onSend(result);
    setBusy(false);
  }, [
    closeSnackbar,
    connection,
    enqueueSnackbar,
    environment,
    nameInfo.name,
    nameInfo.record.isAssignedAndValid,
    nameInfo.record.owner,
    nameInfo.recordType,
    props,
    sendTransaction,
    twilioEndpoint,
    userBrowserLanguage,
    wallet,
  ]);

  return (
    <React.Fragment>
      <Stack>
        <Box sx={{ my: 1.5 }} />
        <Button disabled={props.disabled} onClick={handleSend} variant="contained" endIcon={icon(busy)}>
          Send
        </Button>

        <Typography variant="caption" sx={{ pt: 0.5, textAlign: 'center' }}>
          {nameInfo.record.isAssignedAndValid ? 'direct' : 'via Star Map'}
        </Typography>
      </Stack>
    </React.Fragment>
  );
};
