import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  EscrowState,
  getEscrowAccounts,
  getHashedName,
  RecordType,
  retrieveNameRegistry,
  STARMAP_PROGRAM_ID,
  StarState,
} from 'starmap-api';
import { debounce } from '@mui/material';
import { base58tails, bigintToFloat, recordInfoMessage } from '../utils/Util';
import { Connection, PublicKey } from '@solana/web3.js';
import { Account as SplTokenAccount, getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import { TokenInfo } from '@solana/spl-token-registry';
import { useTokenRegistry } from './TokenRegistry';
import { bonfidaOwner } from '../utils/Bonfida';

export type PendingAccount = {
  index: number;
  address: PublicKey;
  tokenName: string;
  amount: bigint;
  uiAmount: number;
  decimals: number;
  mint: PublicKey;
  sender: PublicKey;
};

type KeyToTokenInfo = { (x: string): TokenInfo | undefined };
export interface INameInfo {
  rawName: string;
  name: string;
  recordType: RecordType;
  record: StarState;
  lookupMessage: string;
  escrowAccounts: EscrowState[];
  pendingAccounts: PendingAccount[];
  programValid: boolean;
  isLoading: boolean;
  onBlockchainUpdate: () => void;
  updateName: (newName: string, normalizedName: string, recordType: RecordType) => void;
  setLookupMessage: (message: string) => void;
  refreshEscrowAccounts: () => void;
}

const NameRecordContext = createContext<INameInfo | null>(null);

async function getPendingAccount(
  connection: Connection,
  escrow: EscrowState,
  getTokenInfo: KeyToTokenInfo
): Promise<PendingAccount[]> {
  /// If we know the mint of the escrow:
  const address = await getAssociatedTokenAddress(escrow.mint, escrow.address, true);
  const tokenInfo = getTokenInfo(escrow.mint.toBase58());
  let accountInfo: SplTokenAccount | null = null;
  try {
    accountInfo = await getAccount(connection, address);
    if (accountInfo == null) throw new Error('Account info for escrow account was null');
  } catch (e: any) {
    console.log(`Error at idx ${escrow.index}`);
    console.log(e);
  }
  console.log(
    `escrow [${escrow.index}] ${base58tails(escrow.mint)} = ${tokenInfo?.symbol} ${accountInfo?.amount || 0}`
  );
  const amount = accountInfo?.amount || BigInt(0);
  const decimals = tokenInfo?.decimals || 0;
  const uiAmount = bigintToFloat(amount.valueOf()) / 10 ** decimals;
  return [
    {
      index: escrow.index,
      address: escrow.address,
      tokenName: tokenInfo?.symbol || 'Error',
      amount,
      uiAmount,
      decimals,
      mint: escrow.mint,
      sender: escrow.sender,
    },
  ];
}

async function checkProgram(connection: Connection): Promise<void> {
  const programInfo = await connection.getAccountInfo(STARMAP_PROGRAM_ID);
  if (programInfo === null) {
    throw new Error('Program needs to be built and deployed');
  } else if (!programInfo.executable) {
    throw new Error(`Program is not executable`);
  }
  console.log(`Using program ${base58tails(STARMAP_PROGRAM_ID)}`);
}

export const NameRecordProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const { getTokenInfoFromKey } = useTokenRegistry();

  const [rawName, setRawNameValue] = useState('');
  const [name, setNameValue] = useState('');
  const [recordType, setRecordType] = React.useState(RecordType.Invalid);
  const [record, setRecord] = React.useState(StarState.empty());
  const [escrowAccounts, setEscrowAccounts] = React.useState<EscrowState[]>([]);
  const [lookupMessage, setLookupMessage] = React.useState('Enter a phone number or email address.');
  const [pendingAccounts, setPendingAccounts] = useState<PendingAccount[]>([]);
  const [programValid, setProgramValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      checkProgram(connection)
        .then(() => setProgramValid(true))
        .catch((e: any) => {
          console.log(`Error loading blockchain program: ${e}`);
          setProgramValid(false);
        });
    })();
  }, [connection]);

  const _refreshEscrowAccounts = useCallback(
    async (normalizedName: string, recordType: RecordType) => {
      setIsLoading(true);
      const allAccounts = await getEscrowAccounts(connection, getHashedName(normalizedName), recordType);
      setEscrowAccounts(allAccounts);
      console.log(`Found ${allAccounts.length} escrow accounts for ${normalizedName}, ${recordType}`);
      if (!allAccounts) {
        setIsLoading(false);
        return;
      }
      const newPending = await Promise.all(
        allAccounts.map((escrow) => getPendingAccount(connection, escrow, getTokenInfoFromKey))
      );
      setPendingAccounts(newPending.flat(1));
      console.log(`Refresh table. Escrows: ${allAccounts.length} Pending: ${newPending.length}`);
      setIsLoading(false);
    },
    [connection, getTokenInfoFromKey]
  );

  const _refreshEscrowAccountsDb = useMemo(() => debounce(_refreshEscrowAccounts, 1000), [_refreshEscrowAccounts]);

  const refreshEscrowAccounts = useCallback(
    () => _refreshEscrowAccountsDb(name, recordType),
    [_refreshEscrowAccountsDb, name, recordType]
  );

  const _updateName = useCallback(
    async (newName, newNormalizedName, newType) => {
      console.log('Retrieving account:', newNormalizedName, 'of type:', newType);
      setIsLoading(true);
      try {
        let newRecord: StarState;
        if (newType == RecordType.Bonfida) {
          const owner = await bonfidaOwner(connection, newNormalizedName);
          newRecord = StarState.create(recordType, owner);
        } else {
          newRecord = await retrieveNameRegistry(connection, newNormalizedName, newType);
        }
        console.log(newRecord);
        setRecord(newRecord);
        setLookupMessage(recordInfoMessage(newNormalizedName, newType, newRecord, wallet.publicKey));
      } catch (e: any) {
        let message;
        if (e instanceof Error) {
          console.log(e);
          message = `Error retrieving name record`;
        } else {
          message = 'An unspecified error occurred';
        }
        console.log(message);
        setLookupMessage(message);
      }
      _refreshEscrowAccountsDb(newNormalizedName, newType);
    },
    [_refreshEscrowAccountsDb, connection, recordType, wallet.publicKey]
  );

  const _updateNameDb = useMemo(() => debounce(_updateName, 1000), [_updateName]);

  const updateName = useCallback(
    (newName, newNormalizedName, newType) => {
      setRawNameValue(newName);
      setNameValue(newNormalizedName);
      setRecordType(newType);
      if (newType == RecordType.Invalid) {
        setEscrowAccounts([]);
        return;
      }
      setLookupMessage(`Searching for ${newNormalizedName}`);
      setIsLoading(true);
      _updateNameDb(newName, newNormalizedName, newType);
    },
    [_updateNameDb]
  );

  const onBlockchainUpdate = useCallback(async () => {
    updateName(rawName, name, recordType);
  }, [name, rawName, recordType, updateName]);

  return (
    <NameRecordContext.Provider
      value={{
        rawName,
        name,
        recordType,
        record,
        lookupMessage,
        escrowAccounts,
        pendingAccounts,
        programValid,
        isLoading,
        onBlockchainUpdate,
        updateName,
        setLookupMessage,
        refreshEscrowAccounts,
      }}
    >
      {children}
    </NameRecordContext.Provider>
  );
};

export const useNameRecord = (): INameInfo => {
  const context = useContext(NameRecordContext);
  if (!context) throw new Error('Missing environment context');
  return context;
};
