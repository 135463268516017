import React, { FC, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RecordType } from 'starmap-api';
import { ClaimButton } from './ClaimButton';
import { VerifyButton } from './VerifyButton';
import { isSelfOwned } from '../../utils/Util';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { EscrowTable } from './EscrowTable';
import { useNameRecord } from '../../context/NamesContext';

export const TabClaim: FC = () => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const nameInfo = useNameRecord();

  const selfOwned = isSelfOwned(nameInfo.record, wallet.publicKey);
  const [lowBalance, setLowBalance] = React.useState(false);
  const [invite, setInvite] = React.useState('');

  useEffect(() => {
    const checks = async () => {
      if (nameInfo.recordType == RecordType.Invalid) {
        setInvite('Enter an alias above');
      } else if (nameInfo.recordType != RecordType.Email && nameInfo.recordType != RecordType.Phone) {
        setInvite("Star Map doesn't manage ownership of this record type");
      } else if (!wallet.connected || wallet.publicKey == null) {
        setInvite('Connect your wallet');
      } else if (selfOwned) {
        setInvite('You own this alias');
      } else {
        const balance = await connection.getBalance(wallet.publicKey);
        if (balance < 0.02) {
          setLowBalance(true);
          setInvite('Insufficient SOL in your wallet (< 0.02 ◎)');
        } else if (nameInfo.record.isReadyToAssign) {
          const location = nameInfo.record.recordType == RecordType.Phone ? 'Phone' : 'Email';
          setInvite(`Check your ${location} for a verification code, enter it below, and click "Finish"`);
        } else {
          setInvite('You can link this alias to your wallet for about 0.0025 ◎');
        }
      }
    };
    checks();
  }, [
    connection,
    nameInfo.record.isReadyToAssign,
    nameInfo.record.recordType,
    nameInfo.recordType,
    selfOwned,
    wallet.connected,
    wallet.publicKey,
  ]);

  return (
    <Stack spacing={2}>
      <Typography sx={{ color: 'text.primary' }}>{invite}</Typography>

      <ClaimButton
        name={nameInfo.name}
        recordType={nameInfo.recordType}
        record={nameInfo.record}
        lowBalance={lowBalance}
        onClaim={nameInfo.onBlockchainUpdate}
      />

      <VerifyButton
        name={nameInfo.name}
        recordType={nameInfo.recordType}
        record={nameInfo.record}
        lowBalance={lowBalance}
        onVerify={nameInfo.onBlockchainUpdate}
      />

      {nameInfo.escrowAccounts.length > 0 && (
        <Box>
          <Typography variant="h5" sx={{ pb: 2 }}>
            Pending Transfers
          </Typography>
          <EscrowTable withdrawToSender={false} withdrawLabel="Claim" />
        </Box>
      )}
    </Stack>
  );
};
