import React, { useCallback, FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { EscrowState, RecordType, StarState } from 'starmap-api';
import { TabSend } from './TabSend';
import { TabClaim } from './TabClaim';
import { TabManage } from './TabManage';
import { useNameRecord } from '../../context/NamesContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabSubPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TabPanel: FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const nameInfo = useNameRecord();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ px: 5, border: 1, borderRadius: 5, borderColor: 'divider' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="main actions tab panel" centered>
            <Tab label="Send" {...a11yProps(0)} />
            <Tab label="Receive" {...a11yProps(1)} />
            <Tab label="Manage" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabSubPanel value={tabIndex} index={0}>
          <TabSend />
        </TabSubPanel>
        <TabSubPanel value={tabIndex} index={1}>
          <TabClaim />
        </TabSubPanel>
        <TabSubPanel value={tabIndex} index={2}>
          <TabManage />
        </TabSubPanel>
      </Box>
    </React.Fragment>
  );
};
