import { Commitment, Connection, PublicKey } from '@solana/web3.js';
import { parsePhoneNumber } from 'libphonenumber-js';
import { RecordType, retrieveNameRegistry, RUST_DEFAULT_PUBLIC_KEY, StarState } from 'starmap-api';

export function recordTypeToTwilioType(recordType: RecordType) {
  switch (recordType) {
    case RecordType.Phone: {
      return 'sms';
    }
    case RecordType.Email: {
      return 'email';
    }
    default: {
      throw new Error('Cannot map record type to Twilio: ${recordType}');
    }
  }
}

export function isSelfOwned(record: StarState, walletPublicKey: PublicKey | null) {
  return record.isAssignedAndValid && walletPublicKey !== null && record.owner.equals(walletPublicKey);
}

export function isDefault(key: PublicKey) {
  return key.equals(PublicKey.default) || key.equals(RUST_DEFAULT_PUBLIC_KEY);
}

export function bigintToFloat(val: bigint) {
  const lower = Number(BigInt.asUintN(32, val)) * 1.0;
  const upper = Number(BigInt.asUintN(32, val / BigInt(2 ** 32))) * 1.0 * 2 ** 32;
  const error = val - BigInt(lower + upper);
  if (error > 0) {
    console.log(`${val} -> ${BigInt(lower + upper)} delta = ${error}`);
  }
  return lower + upper;
}

export function base58tails(key: PublicKey | null, chars?: number | undefined) {
  if (!key) return '';
  chars = chars == undefined ? 4 : chars;
  const full = key.toBase58();
  return full.substring(0, chars) + '...' + full.substring(full.length - chars);
}

export function recordInfoMessage(
  name: string,
  type: RecordType,
  record: StarState,
  walletPublicKey: PublicKey | null
) {
  const niceName = type == RecordType.Phone ? parsePhoneNumber(name).formatInternational() : name;
  if (record.isAssignedAndValid) {
    if (isSelfOwned(record, walletPublicKey)) {
      return `${niceName} is assigned to your wallet.`;
    } else {
      return `${niceName} is assigned to: ${record.owner.toBase58()}`;
    }
  } else if (record.isAuthorized) {
    return `A record for ${niceName} exists, but is ready to claim.`;
  } else if (record.isReadyToAssign) {
    return `A record for ${niceName} exists, and is ready to be verified.`;
  } else if (record.isPresent) {
    return `A record for ${niceName} exists, but its status is "${record.invalidReason}"`;
  }
  return `${niceName} is unclaimed.`;
}

export async function confirmTransaction(
  connection: Connection,
  signature: string,
  commitment: Commitment = 'confirmed'
) {
  const startTime = new Date();
  const result = await connection.confirmTransaction(signature, commitment);
  if (result.value.err) throw new Error('Error confirming transaction: ' + JSON.stringify(result.value.err));
  console.log('Transaction %s after %sms', commitment, new Date().getTime() - startTime.getTime());
  return result.value;
}
