import { useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Transaction, Connection } from '@solana/web3.js';
import React, { useCallback, FC, useMemo, useState } from 'react';
import { CircularProgress, FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';
import { RecordType, StarState, updateNotificationPreference } from 'starmap-api';
import { isSelfOwned } from '../../utils/Util';
import { useSnackbar } from 'notistack';
import { useSendTransaction } from '../../utils/SendTransaction';

async function sendUpdateNotifyPref(
  connection: Connection,
  wallet: WalletContextState,
  name: string,
  type: RecordType,
  setDisabled: boolean
) {
  if (!wallet.publicKey) {
    throw new WalletNotConnectedError();
  }
  console.log('Update disable notifications preference to:', setDisabled);
  const instr = await updateNotificationPreference(name, type, wallet.publicKey, setDisabled);
  const trans = new Transaction();
  trans.add(instr);
  return wallet.sendTransaction(trans, connection);
}

type Props = {
  name: string;
  recordType: RecordType;
  record: StarState;
  onChange: (success: boolean) => void;
};

export const NotifyPreferenceToggle: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const selfOwned = isSelfOwned(props.record, wallet.publicKey);
  const [busy, setBusy] = useState(false);
  const { sendTransaction } = useSendTransaction();
  const isEmail = useMemo(() => props.recordType === RecordType.Email, [props.recordType]);
  const buttonDisabled = useMemo(() => !isEmail || !selfOwned, [isEmail, selfOwned]);
  const enableNotifications = useMemo(
    () => !props.record.flags.disable_notifications,
    [props.record.flags.disable_notifications]
  );
  const notificationsStatus = useMemo(() => {
    if (!isEmail || !isSelfOwned) return '';
    return enableNotifications ? 'enabled' : 'disabled';
  }, [enableNotifications, isEmail]);

  const onChange = useCallback(
    async (event, newValue) => {
      if (props.recordType === RecordType.Invalid) {
        return;
      }
      let result = false;
      let message = '';
      setBusy(true);
      try {
        const promise = sendUpdateNotifyPref(connection, wallet, props.name, props.recordType, !newValue);
        result = await sendTransaction(promise);
        message = result ? 'Notification preference changed' : 'Notification preference update failed';
      } catch (e: any) {
        message = `Notification preference updated failed: ${e}.`;
      }

      setBusy(false);
      console.log(message);
      enqueueSnackbar(message, { variant: result ? 'success' : 'error' });
      props.onChange(result);
    },
    [connection, enqueueSnackbar, props, sendTransaction, wallet]
  );

  return (
    <React.Fragment>
      {!busy && (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={buttonDisabled}
                checked={enableNotifications}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={`Notifications ${notificationsStatus}`}
          />
        </FormGroup>
      )}
      {busy && (
        <Stack direction="row" alignItems="center">
          {' '}
          <CircularProgress color="inherit" size={19} sx={{ mr: 1 }} />{' '}
          <Typography> Updating Notification Preference</Typography>
        </Stack>
      )}
    </React.Fragment>
  );
};
