import React, { FC, ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AccountLayout, RawAccount, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
//import { useConnectedWallet } from '@saberhq/use-solana';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useTokenRegistry } from './TokenRegistry';
import { bigintToFloat } from '../utils/Util';
import { useLocale } from './LocaleContext';

export const WRAPPED_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112');

export type TokenAccount = {
  amount: bigint;
  mint: PublicKey;
  account: PublicKey; // associated token account pubkey
};

export type OwnedTokenAccountsContextT = {
  isLoading: boolean;
  balances: Record<string, TokenAccount[]>; // key: mint
  getAmount: (x: string) => bigint;
  getUIAmount: (x: string) => number;
  getUIAmountString: (x: string) => string;
  refreshTokenAccounts: () => void;
  subscribeToTokenAccount: (pk: PublicKey) => void;
};

export const OwnedTokenAccountsContext = createContext<OwnedTokenAccountsContextT | null>(null);

const convertAccountInfoToTokenAccount = (_accountInfo: RawAccount, pubkey: PublicKey): TokenAccount => {
  const amount = _accountInfo.amount;
  return {
    amount,
    mint: new PublicKey(_accountInfo.mint),
    // public key for the specific token account (NOT the wallet)
    account: pubkey,
  };
};

/**
 * State for the Wallet's SPL accounts and solana account.
 *
 * Fetches and subscribes to all of the user's SPL Tokens on mount
 */
export const AccountsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { connection } = useConnection();
  const { enqueueSnackbar } = useSnackbar();
  const { getTokenInfoFromKey } = useTokenRegistry();
  const { localizedNumber } = useLocale();
  const wallet = useWallet();
  const [loadingOwnedTokenAccounts, setLoading] = useState(false);
  const [ownedTokenAccounts, setOwnedTokenAccounts] = useState<Record<string, TokenAccount[]>>({});
  const [refreshCount, setRefreshCount] = useState(0);
  const subscriptionsRef = useRef<Record<string, number>>({});
  const refreshTokenAccounts = useCallback(() => {
    setRefreshCount((count) => count + 1);
  }, []);

  /**
   * Subscribes to the Public Key of a Token Account if it's not currently
   * subscribed to
   */
  const subscribeToTokenAccount = useCallback(
    (publicKey: PublicKey) => {
      if (subscriptionsRef.current[publicKey.toString()]) {
        // short circuit if a subscription already exists
        return;
      }
      const subscriptionId = connection.onAccountChange(publicKey, (_account) => {
        const listenerAccountInfo = AccountLayout.decode(_account.data);
        const listenerAccount = convertAccountInfoToTokenAccount(listenerAccountInfo, publicKey);
        setOwnedTokenAccounts((prevOwnedTokenAccounts) => {
          const mintAsString = listenerAccount.mint.toString();
          const prevMintState = prevOwnedTokenAccounts[mintAsString];
          let index = prevMintState?.findIndex((prevAccount) => prevAccount.account.equals(publicKey));
          // index may be -1 if the Token Account does not yet exist in our state.
          // In this case, we must set the index to 0 so it will be at the beginning of the array.
          if (index == null || index < 0) {
            index = 0;
          }
          // replace prev state with updated state
          const mintState = Object.assign([], prevMintState, {
            [index]: listenerAccount,
          });
          return {
            ...prevOwnedTokenAccounts,
            [mintAsString]: mintState,
          };
        });
      });
      subscriptionsRef.current[publicKey.toString()] = subscriptionId;
    },
    [connection]
  );

  useEffect(() => {
    const currentSubs = subscriptionsRef.current;
    // Clean up subscriptions when component unmounts
    return () => {
      Object.values(currentSubs).forEach(connection.removeAccountChangeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.removeAccountChangeListener]);

  useEffect(() => {
    // Fetch and subscribe to Token Account updates on mount
    (async () => {
      if (!wallet?.connected || !wallet?.publicKey) {
        // short circuit when there is no wallet connected
        return;
      }
      setLoading(true);
      try {
        const resp = await connection.getTokenAccountsByOwner(
          wallet.publicKey,
          {
            programId: TOKEN_PROGRAM_ID,
          },
          connection.commitment
        );
        const _ownedTokenAccounts = {} as Record<string, TokenAccount[]>;
        if (resp?.value) {
          resp.value.forEach(({ account, pubkey }) => {
            const accountInfo = AccountLayout.decode(account.data);
            const initialAccount = convertAccountInfoToTokenAccount(accountInfo, pubkey);
            const mint = initialAccount.mint.toString();
            if (_ownedTokenAccounts[mint]) {
              _ownedTokenAccounts[mint].push(initialAccount);
            } else {
              _ownedTokenAccounts[mint] = [initialAccount];
            }
            if (!subscriptionsRef.current[pubkey.toString()]) {
              // Subscribe to the SPL token account updates only if no subscription exists for this token.
              subscribeToTokenAccount(new PublicKey(pubkey));
            }
          });
        }

        // Create a wrapped-sol account for native sol balance
        const lamports = await connection.getBalance(wallet.publicKey);
        const nativeAccount: TokenAccount = {
          amount: BigInt(lamports).valueOf(),
          mint: WRAPPED_SOL_MINT,
          account: wallet.publicKey,
        };
        const wSolMint = WRAPPED_SOL_MINT.toString();
        if (_ownedTokenAccounts[wSolMint]) {
          _ownedTokenAccounts[wSolMint].push(nativeAccount);
        } else {
          _ownedTokenAccounts[wSolMint] = [nativeAccount];
        }

        setOwnedTokenAccounts(_ownedTokenAccounts);
      } catch (err) {
        console.log(err);
        enqueueSnackbar(`${err}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    })();
  }, [
    wallet?.connected,
    connection,
    wallet?.publicKey,
    enqueueSnackbar,
    refreshCount, // this triggers reload
    subscribeToTokenAccount,
  ]);

  const getAmount = useCallback(
    (mint: string) => {
      return ownedTokenAccounts[mint].reduce((previousValue, currentValue) => {
        return previousValue + currentValue.amount;
      }, BigInt(0));
    },
    [ownedTokenAccounts]
  );

  const getUIAmount = useCallback(
    (mint: string) => {
      const rawAmount = getAmount(mint);
      const info = getTokenInfoFromKey(mint);
      if (!info) return bigintToFloat(rawAmount);
      return bigintToFloat(rawAmount) / 10.0 ** info.decimals;
    },
    [getAmount, getTokenInfoFromKey]
  );

  const getUIAmountString = useCallback(
    (mint: string) => {
      return localizedNumber(getUIAmount(mint));
    },
    [getUIAmount, localizedNumber]
  );

  return (
    <OwnedTokenAccountsContext.Provider
      value={{
        isLoading: loadingOwnedTokenAccounts,
        balances: ownedTokenAccounts,
        getAmount,
        getUIAmount,
        getUIAmountString,
        refreshTokenAccounts,
        subscribeToTokenAccount,
      }}
    >
      {children}
    </OwnedTokenAccountsContext.Provider>
  );
};

export const useAccounts = (): OwnedTokenAccountsContextT => {
  const context = useContext(OwnedTokenAccountsContext);
  if (!context) throw new Error('Missing accounts context');
  return context;
};
