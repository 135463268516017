import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { envFrom, Environment, useEnvironment } from '../../context/EnvironmentContext';
import { IconButton, ListItemIcon, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Check } from '@mui/icons-material';

const Items = ['Endpoint', Environment.mainnet, Environment.devnet, Environment.localhost];

export default function PositionedMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const envContext = useEnvironment();
  const [selectedIndex, setSelectedIndex] = React.useState(Items.indexOf(envContext.environment));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    envContext.setEnvironment(envFrom(Items[index]));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="open-env-button"
        aria-controls={open ? 'open-env-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: 'text.primary' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="env-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'env-button',
          role: 'listbox',
        }}
      >
        {Items.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{ justifyContent: 'right' }}
          >
            {index === selectedIndex && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
