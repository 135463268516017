import { useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';
import React, { useCallback, FC, useEffect } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { assignNameOwnership, RecordType, StarState } from 'starmap-api';
import axios from 'axios';
import { isSelfOwned, recordTypeToTwilioType } from '../../utils/Util';
import { Connection, Keypair, PublicKey, Signer, Transaction } from '@solana/web3.js';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import * as bs58 from 'bs58';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { useEnvironment } from '../../context/EnvironmentContext';
import { useSendTransaction } from '../../utils/SendTransaction';

type Props = {
  name: string;
  recordType: RecordType;
  record: StarState;
  lowBalance: boolean;
  onVerify: (success: boolean) => void;
};

async function delegatedVerify(
  normalizedName: string,
  recordType: RecordType,
  newOwner: PublicKey,
  code: string,
  twilioEndpoint: string
) {
  console.log('Delegated assign...');
  await axios.post(`${twilioEndpoint}/check-verify`, {
    to: normalizedName,
    channel: recordTypeToTwilioType(recordType),
    verification_code: code,
    recordOwner: newOwner,
  });
}

async function directVerify(
  connection: Connection,
  wallet: WalletContextState,
  name: string,
  type: RecordType,
  claim: Signer,
  newOwner: PublicKey
) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  console.log('Direct assign');
  const instr = await assignNameOwnership(name, type, claim.publicKey, newOwner);
  const trans = new Transaction();
  trans.add(instr);
  return wallet.sendTransaction(trans, connection, { signers: [claim] });
}

function isBs58(input: string) {
  return input.length == 43 || input.length == 44;
}

function startIcon(busy: boolean, record: StarState) {
  if (busy) return <CircularProgress color="inherit" size={19} />;
  return record.isAssignedAndValid ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
}

export const VerifyButton: FC<Props> = (props) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const { chainName, twilioEndpoint } = useEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = React.useState('');
  const { sendTransaction } = useSendTransaction();
  const showDevnetHint = React.useMemo(() => {
    return props.recordType != RecordType.Invalid && chainName == WalletAdapterNetwork.Devnet;
  }, [chainName, props.recordType]);
  const buttonDisabled = React.useMemo(() => {
    const selfOwned = isSelfOwned(props.record, wallet.publicKey);
    const invalid = props.recordType === RecordType.Invalid;
    const indirectEmail = props.recordType == RecordType.Email && !isBs58(code) && code.length > 0;
    const notPaid = !props.record.isReadyToAssign;
    return invalid || selfOwned || props.lowBalance || indirectEmail || notPaid;
  }, [code, props.lowBalance, props.record, props.recordType, wallet.publicKey]);
  const [hidden, setHidden] = React.useState(false);
  const [busy, setBusy] = React.useState(false);

  useEffect(() => {
    setHidden(props.recordType == RecordType.Stars);
  }, [code, props.lowBalance, props.record, props.recordType, wallet.publicKey]);

  const handleCodeChange = useCallback(async (event) => {
    const code = (event.target.value as string).replace(' ', '');
    setCode(code);
  }, []);

  const handleVerify = useCallback(async () => {
    if (props.recordType === RecordType.Invalid) {
      console.log('Cannot verify; type is invalid');
      enqueueSnackbar(`Verify failed: invalid record type`, { variant: 'error' });
      return;
    }
    if (wallet.publicKey == null) {
      enqueueSnackbar(`Verify failed: invalid wallet address`, { variant: 'error' });
      console.log('Cannot verify; wallet.publicKey == null');
      return;
    }
    if (code.length == 0) {
      enqueueSnackbar(`Verify failed: invalid verification code`, { variant: 'error' });
      console.log('no code entered');
      return;
    }
    setBusy(true);
    let result: boolean;
    let message = '';
    try {
      if (code.length == 43 || code.length == 44) {
        const claim = Keypair.fromSeed(bs58.decode(code));
        const promise = directVerify(connection, wallet, props.name, props.recordType, claim, wallet.publicKey);
        result = await sendTransaction(promise);
      } else {
        await delegatedVerify(props.name, props.recordType, wallet.publicKey, code, twilioEndpoint);
        result = true;
      }
      message = `${result ? 'Successfully' : 'failed to'} assign name record ownership`;
    } catch (e: any) {
      message = `Verify failed: ${e}.`;
      result = false;
    }
    setBusy(false);
    enqueueSnackbar(message, { variant: result ? 'success' : 'error' });
    console.log(message);
    props.onVerify(result);
  }, [code, connection, enqueueSnackbar, props, sendTransaction, twilioEndpoint, wallet]);

  // cSpell:ignore 4vJ9JU1bJJE96FWSJKvHsmmFADCg4gpZQff4P3bkLKi
  return (
    <React.Fragment>
      <TextField
        hidden={hidden}
        id="code"
        label="Verification Code"
        variant="outlined"
        value={code}
        onChange={handleCodeChange}
      />
      {showDevnetHint && (
        <Typography variant="caption">
          Devnet hint:{' '}
          {props.recordType == RecordType.Email ? '4vJ9JU1bJJE96FWSJKvHsmmFADCg4gpZQff4P3bkLKi' : 'Enter any code'}
        </Typography>
      )}
      <Box sx={{ alignItems: 'center' }}>
        {startIcon(busy, props.record)}
        <Button disabled={buttonDisabled} hidden={hidden} onClick={handleVerify} variant="contained" sx={{ ml: 2 }}>
          Finish verification
        </Button>
      </Box>
    </React.Fragment>
  );
};
