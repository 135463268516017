import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import React, { FC, ReactNode, useContext, useMemo } from 'react';
import { useLocalStorageState } from './LocalStorage';

export enum Environment {
  mainnet = 'mainnet',
  testnet = 'testnet',
  devnet = 'devnet',
  localhost = 'localhost',
}

interface EnvConfig {
  environment: Environment;
  chainName: WalletAdapterNetwork;
  setEnvironment: (val: string) => void;
  solanaEndpoint: string;
  twilioEndpoint: string;
}

export function envToChain(env: Environment) {
  switch (env) {
    case Environment.mainnet:
      return WalletAdapterNetwork.Mainnet;
    case Environment.testnet:
      return WalletAdapterNetwork.Testnet;
    case Environment.devnet:
      return WalletAdapterNetwork.Devnet;
    case Environment.localhost:
      return WalletAdapterNetwork.Devnet;
  }
}

const twilioUrlBase = 'https://starmap-server-7989';
// cSpell:ignore twil
const twilioUrlSuffix = '.twil.io';
export function envToTwilioUrl(env: Environment) {
  switch (env) {
    case Environment.mainnet:
      return `${twilioUrlBase}${twilioUrlSuffix}`;
    case Environment.testnet:
      return `${twilioUrlBase}-stage${twilioUrlSuffix}`;
    case Environment.devnet:
      return `${twilioUrlBase}-dev${twilioUrlSuffix}`;
    case Environment.localhost:
      return 'http://localhost:4000';
  }
}

export function useSolanaExplorerUrlSuffix(env: Environment) {
  if (env === Environment.devnet) {
    return '?cluster=devnet';
  } else if (env === Environment.testnet) {
    return '?cluster=testnet';
  }
  return '';
}

export function envFrom(envString: string) {
  const envKey = envString as keyof typeof Environment;
  return Environment[envKey];
}

export function envToSolanaEndpoint(network: Environment) {
  switch (network) {
    case Environment.localhost:
      return 'http://127.0.0.1:8899';
    case Environment.mainnet:
      return 'https://shy-autumn-darkness.solana-mainnet.quiknode.pro/9a578a969bd02fee4f4176fb35d3397ea215eb24/';
    default:
      return clusterApiUrl(envToChain(network));
  }
}

const defaultEnv = Environment.mainnet;

export const EnvContext = React.createContext<EnvConfig | null>(null);

export const EnvContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [envString, setEnvironment] = useLocalStorageState('environment', defaultEnv);
  const environment = useMemo<Environment>(() => {
    try {
      return envFrom(envString);
    } catch {
      console.log('caught', envString);
      return defaultEnv;
    }
  }, [envString]);
  const solanaEndpoint = useMemo(() => envToSolanaEndpoint(environment), [environment]);
  const chainName = useMemo(() => envToChain(environment), [environment]);
  console.log('envString:', envString, 'env:', environment, 'endpoint:', solanaEndpoint);

  const twilioEndpoint = useMemo(() => envToTwilioUrl(environment), [environment]);

  return (
    <EnvContext.Provider
      value={{
        environment,
        chainName,
        setEnvironment,
        solanaEndpoint,
        twilioEndpoint,
      }}
    >
      {children}
    </EnvContext.Provider>
  );
};

export const useEnvironment = (): EnvConfig => {
  const context = useContext(EnvContext);
  if (!context) throw new Error('Missing environment context');
  return context;
};
