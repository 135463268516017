import React, { useCallback, useContext, useMemo } from 'react';
import { FC, ReactNode } from 'react';

interface UserLocale {
  userBrowserLanguage: string;
  userNumberLocale: string;
  localizedNumber: (
    value: number,
    precision?: number,
    minimumFractionDigits?: number,
    maximumFractionDigits?: number
  ) => string;
  numberParser: (input: string) => number;
}

export class NumberParser {
  _decimal!: RegExp;
  _group!: RegExp;
  _numeral!: RegExp;
  _index!: (input: string) => string;

  constructor(locale: string) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));

    const g = parts.find((d) => d.type === 'group');
    const d = parts.find((d) => d.type === 'decimal');
    if (g == undefined || d == undefined) throw new Error('NumberParser init failure');

    this._group = new RegExp(`[${g.value}]`, 'g');
    this._decimal = new RegExp(`[${d.value}]`);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = (d) => index.get(d)?.toString() || '';
  }
  parse(input: string) {
    const parsed = (input = input
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index));
    return parsed ? +input : NaN;
  }
}

export const LocaleContext = React.createContext<UserLocale | null>(null);

export const LocaleContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const userBrowserLanguage = useMemo(() => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en';
    }
  }, []);

  const userNumberLocale = useMemo(() => new Intl.NumberFormat().resolvedOptions().locale, []);

  const localizedNumber = useCallback(
    (
      value: number,
      precision: number | undefined = undefined,
      minimumFractionDigits = 0,
      maximumFractionDigits = 6
    ) => {
      const roundedValue = precision == undefined ? value : Number(value.toPrecision(precision));
      return roundedValue.toLocaleString([userBrowserLanguage, 'en-EN'], {
        minimumFractionDigits,
        maximumFractionDigits,
      });
    },
    [userBrowserLanguage]
  );

  const numberParser = useCallback(
    (input: string) => {
      return new NumberParser(userBrowserLanguage).parse(input);
    },
    [userBrowserLanguage]
  );

  return (
    <LocaleContext.Provider value={{ userBrowserLanguage, userNumberLocale, localizedNumber, numberParser }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = (): UserLocale => {
  const context = useContext(LocaleContext);
  if (!context) throw new Error('Missing locale context');
  return context;
};
